import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';

import { SectionTitle } from 'components/Common';
import {
  Wrapper,
  Content,
  Column,
  Subtitle,
  Text,
  CoverWrapper,
  Cover,
} from './styles';

interface CoverQuery {
  file: {
    childImageSharp: {
      fluid: FluidObject | FluidObject[];
    };
  };
}

const data = graphql`
  {
    file(relativePath: { eq: "images/about.png" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 366, maxHeight: 465) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

type Props = {
  id: string;
};

const AboutHeaderView: FC<Props> = ({ id }) => {
  const { file } = useStaticQuery<CoverQuery>(data);

  return (
    <Wrapper id={id}>
      <Content>
        <Column>
          <SectionTitle>Simple Finance</SectionTitle>
          <Subtitle>
            Zespół profesjonalistów od wielu lat współpracujących z klientami,
            zwłaszcza biznesowymi z różnych branż w sektorze mikro i małych
            przedsiębiorstw.
          </Subtitle>
          <Text>
            Specjalizujemy się między innymi w obsłudze „trudnych kredytów”.
            Nasz zespół tworzy kilkanaście osób z bogatym, wieloletnim
            doświadczeniem na rynku finansowym, zdobytym na stanowiskach
            dyrektorskich i doradczych w wielu znanych instytucjach finansowych,
            bankach i funduszach. Dzięki naszej wiedzy i doświadczeniu potrafimy
            przygotować dla Ciebie ofertę najlepiej dopasowaną do Twoich
            potrzeb. Budujemy partnerskie relacje z klientami i oferujemy
            profesjonalną pomoc w realizacji indywidualnych celów biznesowych,
            ponieważ zależy nam na rozwoju Twojej działalności.
          </Text>
          <Text>
            Współpracujemy z wieloma różnymi bankami i firmami pozabankowymi –
            dzięki czemu znajdziemy dla Ciebie rozwiązanie nawet jeśli myślisz,
            że sytuacja Twoja lub Twojej firmy jest bez wyjścia. Działamy szybko
            i skutecznie. Kontaktując się z nami telefonicznie – szybko uzyskasz
            odpowiedzi na wszystkie pytania oraz informacje o ofercie.
            Zapewniamy współpracę zdalną – możesz załatwić z nami wszystkie
            formalności nie wychodząc z domu.
          </Text>
        </Column>
        <CoverWrapper>
          <Cover fluid={file.childImageSharp.fluid} draggable={false} />
        </CoverWrapper>
      </Content>
    </Wrapper>
  );
};

export default AboutHeaderView;
