import styled from 'styled-components';
import Img, { FluidObject } from 'gatsby-image';
import {
  SectionWrapper,
  ContentWrapper,
  SectionSubtitle,
} from 'components/Common';

export const Wrapper = styled(SectionWrapper)`
  margin: 13.5rem auto 10rem;
`;

export const Content = styled(ContentWrapper)`
  display: flex;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  flex: 1 0 100%;
  ${({ theme }) => theme.mq.mobile} {
    flex: 1 0 300px;
    max-width: 540px;
    margin-right: 4rem;
  }
`;

export const Subtitle = styled(SectionSubtitle)`
  margin-bottom: 2.4rem;
`;

export const Text = styled.p`
  margin: 0 0 2.5rem;
  font-size: 1.3rem;
  line-height: 2.8rem;
`;

export const CoverWrapper = styled.div`
  flex: 1 0 100%;
  max-width: 366px;
  max-height: 465px;
  ${({ theme }) => theme.mq.mobile} {
    flex: 1 0 300px;
    margin-bottom: 8rem;
    transform: translateY(5rem);
  }
`;

interface CoverProps {
  fluid: FluidObject | FluidObject[];
}

export const Cover = styled(Img)<CoverProps>`
  border-radius: 140px 15px;
`;
