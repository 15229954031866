import React, { FC } from 'react';
import { PageProps } from 'gatsby';

import Head from 'views/Head';
import AboutView from 'views/AboutHeader';

const AboutPage: FC<PageProps> = () => (
  <>
    <Head title="O nas" />
    <AboutView id="about" />
  </>
);

export default AboutPage;
